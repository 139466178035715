<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    seccionDetalle="Detalles Generales"
  >
    <!-- INFORMACIÓN DEL LUGAR -->
    <Lugar class="mb-5" :asignacion="asignacion"></Lugar>

    <!-- INFORMACIÓN DE ATENCIÓN -->
    <Atencion class="mb-5" :asignacion="asignacion"></Atencion>
    <!-- FOTOS INICIALES -->

    <!-- TAREAS -->
    <Tareas class="mb-5" :asignacion="asignacion"></Tareas>

    <!-- FOTO DE VISITA -->

    <!-- FOTOS FINALES -->
  </LayoutView>
</template>

<script>

import { mapState } from "vuex";
import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";
import LayoutView from "@/layouts/detalles.vue";

import Lugar from "@/components/asignaciones/detalle-general/lugar.vue";
import Atencion from "@/components/asignaciones/detalle-general/atencion.vue";
import Tareas from "@/components/asignaciones/detalle-general/tareas.vue";

export default {
  components: {
    LayoutView,
    Tareas,
    Atencion,
    Lugar
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      asignacion: null,
      tareas: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    init() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;
        });
    },
  },
};
</script>
