<template>
  <div class="row" v-if="asignacion">
    <div class="d-flex justify-content-between align-items-center">
      <p class="h5 detalles-asignacion__sub font-italic my-0">
        Información del Lugar
      </p>
    </div>

    <section class="mt-3">
      <!-- IMAGEN DEL NEGOCIO LOCAL -->
      <div class="d-flex justify-content-center align-items-center">
        <img
          :src="asignacion.local.imagen"
          :alt="asignacion.local.nomLocal"
          class="detalles-asignacion__local-image img-fluid"
        />
      </div>

      <!-- DESCRIPCION DEL SERVICIO ESCRITO POR EL ADMINITRADOR -->
      <div class="row mt-5">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p class="font-weight-bold mr-2">Descripción:</p>
              <hr />
              <p>{{ asignacion.descripcionServicio }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- CARD CON DETALLES DEL NEGOCIO LOCAL -->
      <div class="row mt-3 mt-md-5">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p class="h6 font-weight-bold border-bottom pb-1">
                Datos del local
              </p>
              <p class="h6 card-subtitle my-3 text-muted">
                {{ asignacion.local.nomLocal }}
              </p>
              <hr />
              <p class="h6 card-text mt-3 mb-2">
                <i class="fas fa-map-marker-alt"></i>
                {{ asignacion.direccionLocal }} -
                {{ asignacion.local.departamento }} -
                {{ asignacion.local.provincia }} -
                {{ asignacion.local.distrito }}
              </p>

              <hr />

              <div class="row no-gutters">
                <div class="col-12 ">
                  <span class="card-link h6">
                    <i class="fas fa-mobile-alt mr-2"></i>
                    {{ asignacion.local.celular }}
                  </span>
                </div>

                <div class="col-12  mt-2">
                  <a
                    :href="`mailto:${asignacion.local.emailContacto}`"
                    class="card-link h6"
                  >
                    <span class="text-dark">
                      <i class="far fa-envelope mr-2"></i>
                    </span>

                    {{ asignacion.local.emailContacto }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- INFORMACIÓN DE VISITA DEL SUPERVISOR -->
        <div class="col-md-12 mt-3">
          <div class="card">
            <div class="card-body">
              <template v-if="visitaSupervisor">
                <p class="h6 pb-1 font-weight-bold border-bottom">
                  Datos llenados por supervisor
                </p>

                <p class="card-text mt-3 mb-0 border-bottom">
                  <span class="text-muted">Local reconocido:</span>
                  <span class="small d-inline-block ml-2">{{
                    asignacion.updated_at | moment("LLLL")
                  }}</span>
                </p>

                <p class="card-text mt-3 mb-0 border-bottom">
                  <span class="text-muted">Personal de atención:</span>
                  <span class="small d-inline-block ml-2">{{
                    asignacion.atencion
                  }}</span>
                </p>

                <p class="card-text mt-3 mb-0 border-bottom">
                  <span class="text-muted">Nro. técnicos:</span>
                  {{ asignacion.numerodeTecnicos }}
                </p>

                <div class="mt-3 border-bottom">
                  <span class="text-muted">Observaciones:</span>
                  <p class="my-0 small">
                    {{ asignacion.observaciones }}
                  </p>
                </div>

                <div class="mt-3">
                  <span class="text-muted">Comentarios adicionales:</span>
                  <p class="my-0 small">
                    {{ asignacion.comentariosAdicionales }}
                  </p>
                </div>
              </template>

              <p class="text-center h6 font-weight-bold font-italic" v-else>
                El supervisor asignado aún no ha registrado visita a local.
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr v-if="isAdmin" class="mt-5" />

      <!-- PERSONAL ASIGNADO -->
      <div class="row justify-content-center mt-5" v-if="isAdmin">
        <!-- Solo admin -->
        <div class="col-md-12">
          <p
            class="h6 font-italic detalles-asignacion__sub font-weight-bold my-0"
          >
            Personal asignado
          </p>
        </div>

        <div class="col-md-12 mt-3">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body d-flex flex-column">
                  <span class="text-muted mb-2">Supervisor:</span>

                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img
                      :src="asignacion.supervisor.foto"
                      :alt="asignacion.supervisor.nombres"
                      class="foto"
                    />

                    <span class="text-muted d-inline-block mt-2 h5">
                      {{
                        `${asignacion.supervisor.nombres} ${asignacion.supervisor.apellidos}`
                      }}
                    </span>
                  </div>

                  <div class="row no-gutters mt-3 small">
                    <div class="col-12 col-md-6">
                      <span>
                        <i class="fas fa-mobile-alt mr-1"></i>
                        {{ asignacion.supervisor.celular }}</span
                      >
                    </div>
                    <div class="col-12 col-md-6">
                      <span
                        ><i class="far fa-envelope mr-1"></i>
                        {{ asignacion.supervisor.email }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mt-3 mt-md-0">
              <div class="card">
                <div class="card-body d-flex flex-column">
                  <span class="text-muted mb-2">Contratista:</span>

                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img
                      :src="asignacion.contratista.foto"
                      :alt="asignacion.contratista.nombres"
                      class="img-fluid foto"
                    />

                    <span class="text-muted d-inline-block mt-2 h5">
                      {{
                        `${asignacion.contratista.nombres} ${asignacion.contratista.apellidos}`
                      }}
                    </span>
                  </div>

                  <div class="row no-gutters mt-3 small">
                    <div class="col-12 col-md-6">
                      <span>
                        <i class="fas fa-mobile-alt mr-1"></i>
                        {{ asignacion.contratista.celular }}</span
                      >
                    </div>
                    <div class="col-12 col-md-6">
                      <span
                        ><i class="far fa-envelope mr-1"></i>
                        {{ asignacion.contratista.email }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { estados } from "../../../../.env";
import { mapState } from "vuex";
import estadoMixins from "@/mixins/estadoMixins.js";
export default {
  props: { asignacion: { type: Object } },
  mixins: [estadoMixins],
  data() {
    return {
      estados: estados,
    };
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    isAdmin() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    visitaSupervisor() {
      let status = false;

      let { latitud, longitud } = this.asignacion;

      if (latitud && longitud) {
        status = true;
      }

      return status;
    },
  },
};
</script>
