<template>
  <div class="row" v-if="asignacion">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <p class="h5 detalles-asignacion__sub font-italic my-0">
          Información de Atención del Personal
        </p>
      </div>

      <hr />

      <section class="mt-3">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="atencion">Nombre del Personal de atención</label>
              <input
                type="text"
                disabled
                id="atencion"
                class="form-control"
                v-model="asignacion.atencion"
              />
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="celularAtencion"
                >Celular del Personal de atención</label
              >
              <input
                type="text"
                disabled
                id="celularAtencion"
                class="form-control"
                v-model="asignacion.celularAtencion"
              />
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="form-group">
              <label for="correoAtencion"
                >Correo del Personal de atención</label
              >
              <input
                type="text"
                disabled
                id="correoAtencion"
                class="form-control"
                v-model="asignacion.correoAtencion"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="observaciones">Observaciones</label>
              <textarea
                disabled
                id="observaciones"
                class="form-control"
                v-model="asignacion.observaciones"
              ></textarea>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="numerodeTecnicos">Nro de técnicos</label>
              <input
                type="number"
                min="0"
                disabled
                class="form-control"
                v-model="asignacion.numerodeTecnicos"
              />
            </div>
          </div>

          <div class="col-md-6 col-lg-8">
            <div class="form-group">
              <label for="comentariosAdicionales"
                >Comentarios adicionales</label
              >
              <textarea
                id="comentariosAdicionales"
                class="form-control"
                disabled
                v-model="asignacion.comentariosAdicionales"
              ></textarea>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: { asignacion: { type: Object } },
};
</script>
